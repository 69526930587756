<template>
  <div class="Userhl_faburenwu Userhl_rightbox">
    <h1>我发布的任务模板</h1>
  </div>
</template>
<script>
export default {
  components: {

  },
};
</script>
